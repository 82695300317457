@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MonsterOfFantasy';
  src: url('./assets/fonts/MonsterOfFantasy.otf');
}

@font-face {
  font-family: 'Yozakura';
  src: url('./assets/fonts/YOZAKURA-Regular.ttf');
}

@font-face {
  font-family: 'TimesNow';
  src: url('./assets/fonts/TimesNow-Light.ttf');
}

@font-face {
  font-family: 'Altform';
  src: url('./assets/fonts/Altform-Regular.otf');
}

@font-face {
  font-family: 'Altform';
  src: url('./assets/fonts/Altform-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Altform';
  src: url('./assets/fonts/Altform-Light.otf');
  font-weight: 300;
}

@layer base {
  html {
    @apply font-timesNow;
  }

  h1 {
    @apply text-h1;
    @apply font-monsterOfFantasy;
  }

  h2 {
    @apply text-h2;
    @apply font-monsterOfFantasy;
  }

  h3 {
    @apply text-h3;
    @apply font-monsterOfFantasy;
  }

  h4 {
    @apply text-h4;
    @apply font-monsterOfFantasy;
  }

  h5 {
    @apply text-h5;
    @apply font-monsterOfFantasy;
  }

  p {
    @apply text-p;
    @apply font-timesNow;
  }
}

@function generate-random-stars($screen-width, $color, $density) {
  $h-shadow: random($screen-width);
  $v-shadow: random($screen-width);

  $stars: '#{$h-shadow}px #{$v-shadow}px #{$color}';
  @for $i from 2 through $density {
    $h-shadow: random($screen-width);
    $v-shadow: random($screen-width);

    $stars: '#{$stars}, #{$h-shadow}px #{$v-shadow}px #{$color}';
  }
  @return unquote($stars);
}

@mixin stars-content($screen-width, $color, $density, $speed, $delay) {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: generate-random-stars($screen-width, $color, $density);
  animation-name: glowing-stars;
  animation-duration: $speed + s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-delay: $delay + s;
}

html {
  overscroll-behavior: none;
}
body {
  overscroll-behavior-y: contain;
}

.stroke-animation {
  // common
  &.light-variant {
    path {
      stroke: theme('colors.purple');
    }
  }

  // the beginning: chapter-circle-three.svg
  &.the-beginning {
    .triangle {
      animation-delay: 2400ms;
    }

    .short-line {
      animation-delay: 2400ms;
    }

    .inner-line-circle-three {
      animation-delay: 1800ms;
    }

    .inner-line-circle-three {
      animation-delay: 1800ms;
    }

    .inner-line-circle-three {
      animation-delay: 1800ms;
    }
  }

  .short-line {
    stroke-dasharray: 60;
    stroke-dashoffset: 60;
    animation: dash 600ms ease-in-out forwards;
    animation-delay: 600ms;
  }

  .triangle {
    stroke-dasharray: 80;
    stroke-dashoffset: 80;
    animation: dash 600ms ease-in-out forwards;
    animation-delay: 600ms;
  }

  .square {
    stroke-dasharray: 155;
    stroke-dashoffset: 155;
    animation: dash 600ms ease-in-out forwards;
    animation-delay: 600ms;
  }

  // chapter-circle-one.svg
  .inner-circle-one {
    stroke-dasharray: 310;
    stroke-dashoffset: 310;
    animation: dash 1000ms ease-in-out forwards;
    animation-delay: 200ms;
  }

  .outer-circle-one {
    stroke-dasharray: 1490;
    stroke-dashoffset: 1490;
    animation: dash 1200ms ease-in-out forwards;
  }

  // chapter-circle-two.svg
  .outer-circle-two {
    stroke-dasharray: 1140;
    stroke-dashoffset: 1140;
    animation: dash 1200ms ease-in-out forwards;
  }

  .middle-circle-two {
    stroke-dasharray: 1345;
    stroke-dashoffset: 1345;
    animation: dash 1200ms ease-in-out forwards;
  }

  .large-triangle-circle-two {
    stroke-dasharray: 270;
    stroke-dashoffset: 270;
    animation: dash 1200ms ease-in-out forwards;
  }

  // chapter-circle-three.svg
  .inner-line-circle-three {
    stroke-dasharray: 1400;
    stroke-dashoffset: 1400;
    animation: dash 1200ms ease-in-out forwards;
  }

  .inner-line-circle-three {
    stroke-dasharray: 1430;
    stroke-dashoffset: 1430;
    animation: dash 1200ms ease-in-out forwards;
  }

  .inner-line-circle-three {
    stroke-dasharray: 1470;
    stroke-dashoffset: 1470;
    animation: dash 1200ms ease-in-out forwards;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

//Change colors for multi-colors star groups

#stars-group-1 {
  @include stars-content(3000, #fff, 200, 1, 0);
}

#stars-group-2 {
  @include stars-content(3000, #fff, 200, 1, 0.1);
}

#stars-group-3 {
  @include stars-content(3000, #fff, 200, 1, 0.2);
}

#stars-group-4 {
  @include stars-content(3000, #fff, 200, 1, 0.3);
}

#stars-group-5 {
  @include stars-content(3000, #fff, 200, 1, 0.4);
}

#stars-group-6 {
  @include stars-content(3000, #fff, 200, 1, 0.5);
}

@keyframes glowing-stars {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.masked-overflow {
  --mask-width: 100px;
  --mask-image-content: linear-gradient(
    to right,
    transparent,
    black var(--mask-width),
    black calc(100% - var(--mask-width)),
    transparent
  );
  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: 0 100%;
  mask-image: var(--mask-image-content);
  mask-size: 100%;
}
.vignette {
  height: 100%;
  width: 100%;
  background: radial-gradient(transparent 5%, rgba(0, 0, 0, 0.9) 65%, black);
}

.interaction_circle {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0) 62.94%,
    rgba(255, 255, 255, 0.5) 100%
  );
}

.legals {
  h2,
  h3,
  p {
    font-size: 18px;
    line-height: 32px;
    margin-top: 24px;
  }

  ul {
    @apply font-altform;
    font-size: 18px;
    line-height: 32px;
    list-style-type: disc;
    list-style-position: inside;
    font-weight: 300;
  }

  p {
    @apply font-altform;
    font-weight: 300;
  }
}

.capitals {
  &::first-letter {
    position: relative;
    bottom: 1.5em;
    font-family: 'MonsterOfFantasy';
    font-size: 3.5em;
    line-height: 0;
  }
}

html {
  background-color: #160d4d;
  em {
    font-style: normal;
    color: #ac9fff;
  }
}
* {
  scrollbar-color: white;
  scrollbar-width: 2px;
}
*::-webkit-scrollbar {
  width: 5px;
  position: absolute;
  top: 0;
  right: 15px;
}
*::-webkit-scrollbar-track {
  background: #ac9fff97;
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 14px;
  border: 3px solid white;
}

.wtg-scrollbar {
  &::-webkit-scrollbar-track {
    background: black;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #CC4E4A;
    border-radius: 14px;
    border: 3px solid #CC4E4A;
  }
}

/*
  content mask
*/
.content-mask {
  mask: url('./main/assets/images/content-section/contentMask.png');
  mask-position: center bottom;
  mask-size: contain;
  mask-repeat: no-repeat;
}

/*
  gallery grid areas
*/
.cell-a {
  grid-area: 1 / 1 / 2 / 3;
  @media screen and (min-width: 640px) {
    grid-area: 1 / 1 / 2 / 4;
  }
}
.cell-b {
  grid-area: 4 / 1 / 5 / 2;
  @media screen and (min-width: 640px) {
    grid-area: 2 / 1 / 3 / 2;
  }
}
.cell-c {
  grid-area: 2 / 1 / 3 / 3;
  @media screen and (min-width: 640px) {
    grid-area: 2 / 2 / 3 / 3;
  }
}
.cell-d {
  grid-area: 4 / 2 / 5 / 3;
  @media screen and (min-width: 640px) {
    grid-area: 2 / 3 / 3 / 4;
  }
}
.cell-e {
  grid-area: 3 / 1 / 4 / 3;
  @media screen and (min-width: 640px) {
    grid-area: 3 / 1 / 4 / 4;
  }
}
